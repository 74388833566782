import {
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Space,
	Typography,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppCard from '../../../components/AppCard';
import SectionContent from '../../../components/SectionContent';
import { generateFormRules } from '../../../helpers/formRules';
import { useDebounce } from 'use-debounce';
import AppButton from '../../../components/AppButton';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import useFetchList from '../../../hooks/useFetchList';
import { BaseResponseProps } from '../../../types/config.type';
import { GenreProps, initialGenre } from '../../../types/genre.type';
import { ContainerFilter } from '../../dashboard';
import FormUploadImage from '../../../components/FormUpload/FormUploadImage';

interface IParams {
	genreId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<GenreProps, 'createdAt' | 'updatedAt'>;
}

const GenreEdit: React.FC = () => {
	const { Text } = Typography;
	const history = useHistory();
	const { genreId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<GenreProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [genre, setGenre] = React.useState<GenreProps>(initialGenre);
	const [genreName, setGenreName] = React.useState<string>('');
	const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
	const [searchValue] = useDebounce(genreName, 500);
	const [image, setImage] = React.useState<File | undefined>();
	const [imageUrl, setImageUrl] = React.useState<string | undefined>();
	const { data } = useFetchList<GenreProps>({
		endpoint: 'genre',
		initialQuery: {
			limit: 10,
			offset: 0,
		},
		limit: 10,
	});

	React.useEffect(() => {
		const res: any = data.filter(
			(item) => item.genreId !== genreId && searchValue === item.genreName,
		);
		setIsDuplicate(res.length > 0 ? true : false);
	}, [searchValue]);

	const createGenre = async (
		props: Omit<
			GenreProps,
			'createdAt' | 'updatedAt' | 'genreId' | 'statusLoading'
		>,
	) => {
		try {
			// const formData = new FormData();

			// if (image) {
			// 	formData.append('imageUrl', image);
			// }
			setIsLoadingAction(true);

			await httpRequest.post(
				'/genre',
				{
					...props,
				},
				// {
				// 	headers: {
				// 		'Content-Type': 'multipart/form-data',
				// 	},
				// },
			);
			// if (res && res.data) {
			// 	console.info('res.data', res.data);
			// 	await uploadNewImage(res.data.payload.genreId);
			// }

			message.success('Success create ' + props.genreName);
			history.push('/genre');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateGenre = async (
		props: Omit<GenreProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);

			await httpRequest.patch('/genre/' + genreId, props);
			uploadNewImage(genreId);

			message.success('Success update ' + props.genreName + ' data');
			history.push('/genre');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<GenreProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		if (genreId) {
			updateGenre(values);
		} else {
			createGenre(values);
		}
	};

	const uploadNewImage = async (genreId: string) => {
		try {
			if (image) {
				setIsLoadingAction(true);

				let formData = new FormData();
				formData.append('image', image);

				await httpRequest.put('/genre/' + genreId + '/upload-image', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
				setIsLoadingAction(false);
			}
		} catch (err) {
			console.log(err);
		}
	};
	React.useEffect(() => {
		if (genreId) {
			const fetchGenre = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>('/genre/' + genreId);
					const dataImages = res.data.payload;

					setImageUrl(dataImages.imageUrl);
					
					setGenre(res.data.payload);
					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};

			fetchGenre();
		}
	}, [genreId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(genreId ? 'Edit' : 'Add') + ' Genre'}
				subtitle="Manage your genre data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							disabled={isDuplicate}
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="profileForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={genreId ? genre : initialGenre}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Genre Data"
						helpers={[
							{
								title: 'Information',
								content:
									'Genre must be unique, system will reject if genre has been created with the same name',
							},
						]}
					>
						{/* <FormUploadImage
							mode={'single-large'}
							maxSizeInMB={5}
							imageUrl={imageUrl || ''}
							onChange={(file) => {
								setImage(file);
							}}
							onPreviewChange={(previewUrl) => setImageUrl(previewUrl)}
						/> */}
						<Form.Item
							label="Genre"
							name="genreName"
							rules={generateFormRules('Genre name', ['required'])}
						>
							<Input onChange={(e) => setGenreName(e.target.value)} />
						</Form.Item>
						{isDuplicate && (
							<Text style={{ color: 'red', fontSize: 13 }}>
								Category Name Already Exist!
							</Text>
						)}

						<Form.Item
							label="Status"
							name="isPublished"
							rules={[
								{
									required: true,
									message: 'The Status is required.',
								},
							]}
						>
							<Radio.Group value={genre.isPublished}>
								<Radio value={true}>Published</Radio>
								<Radio value={false}>Unpublished</Radio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default GenreEdit;
