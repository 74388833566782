import {
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Select,
	Space,
	Checkbox,
	Row,
	Col,
	Button,
	InputNumber,
	Tooltip,
	Typography,
} from 'antd';
import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import 'react-quill/dist/quill.snow.css';
import { generateFormRules } from '../../helpers/formRules';
import { generateQueryString } from '../../helpers/generateQueryString';
import { getErrorMessage } from '../../helpers/errorHandler';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import styled from 'styled-components';
import { DrugMedicineProps, initialMedicine } from '../../types/medicine.type';
import { initialMedicine as initialMedicineDoses } from '../../types/doses.type';
import {
	FetchAllPartnerResponse,
	PartnerProps,
} from '../../types/partner.type';
import {
	CategoryProps,
	FetchAllCategoryResponse,
	FetchCategoryResponse,
} from '../../types/category.type';
import TextArea from 'antd/lib/input/TextArea';
import { DeleteOutlined } from '@ant-design/icons';
import { IPagination } from '../../helpers/pagination';

interface IParams {
	drugMedicineId: string;
}

interface ILocation {
	drugMedicineId: string;
	pagination: IPagination;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<DrugMedicineProps, 'createdAt' | 'updatedAt'>;
}

const { Option } = Select;

const MedicineEdit: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const location = useLocation<ILocation>();
	const { drugMedicineId } = useParams<IParams>();
	const formRef =
		React.useRef<
			FormInstance<Omit<DrugMedicineProps, 'createdAt' | 'updatedAt'>>
		>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [medicine, setMedicine] =
		React.useState<DrugMedicineProps>(initialMedicine);

	const [medicineCategories, setMedicineCategories] = React.useState<
		Array<CategoryProps>
	>([]);

	const [dosageName, setDosageName] = React.useState<string>('');

	const [doses, setDoses] = React.useState<string[]>([]);

	const [pharmacyList, setPharmacyList] = React.useState<PartnerProps[]>([]);
	const [genericPoint, setGenericPoint] = React.useState(-1);

	const getGenericPointData = async () => {
		try {
			setIsLoading(true);
			const genericPointData = await httpRequest.get<any>(
				`/app-configs${generateQueryString({
					keys: 'REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY_DOCTOR',
				})}`,
			);
			setGenericPoint(Number(genericPointData.data.payload.results[0].value));
			setIsLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	React.useEffect(() => {
		getGenericPointData();
	}, []);

	React.useEffect(() => {
		if (!medicine.partnerId && genericPoint !== -1) {
			setMedicine((prev) => ({ ...prev, points: genericPoint }));
		}
	}, [genericPoint, medicine.partnerId]);

	const createMedicine = async (
		props: Omit<
			DrugMedicineProps,
			'createdAt' | 'updatedAt' | 'drugMedicineId' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);

			const selectedDoses = doses.map((item) => {
				const foundDosage = {
					drugMedicineId: medicine.drugMedicineId,
					dose: item,
					description: medicine.description,
				};
				return foundDosage;
			});

			const res: any = await httpRequest.post('/drug-medicines', {
				...props,
				categories: medicine.categories,
				partnerId: medicine.partnerId,
				doses: selectedDoses,
			});

			message.success('Success create ' + props.drugMedicineName);
			history.push('/medicine');
			setIsLoadingAction(false);
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const updateMedicine = async (
		props: Omit<DrugMedicineProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);

			const selectedDoses = doses.map((item) => {
				const foundDosage = {
					drugMedicineId: medicine.drugMedicineId,
					dose: item,
					description: medicine.description,
				};
				return foundDosage;
			});

			const dataToBeSent = {
				...props,
				categories: medicine.categories,
				partnerId: medicine.partnerId,
				doses: selectedDoses,
			};
			if (drugMedicineId) {
				await httpRequest.patch(
					'/drug-medicines/' + drugMedicineId,
					dataToBeSent,
				);
			}

			message.success('Success update ' + props.drugMedicineName + ' data');
			history.push({
				pathname: `/medicine`,
				state: { pagination: location.state.pagination },
			});
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<
			DrugMedicineProps,
			'createdAt' | 'updatedAt' | 'statusLoading'
		>,
	) => {
		if(!values.partnerId){
			values.points = genericPoint
		}
		if (drugMedicineId) {
			updateMedicine(values);
		} else {
			createMedicine(values);
		}
	};

	const handleChangeCategories = async (value: any) => {
		const selectedCategories: Array<CategoryProps> = [];

		for (const categoryId of value) {
			const foundCategories = medicineCategories.find(
				(category) => category.categoryId === categoryId,
			);

			if (foundCategories) {
				selectedCategories.push(foundCategories);
			} else {
				const newCategoryData = medicineCategories;

				const resCategory = await httpRequest.post<FetchCategoryResponse>(
					'/medicine-categories',
					{
						categoryName: categoryId,
						isPublished: true,
					},
				);

				// const newCategory = resCategory.data.payload

				newCategoryData.push(resCategory.data.payload);
				setMedicineCategories(newCategoryData);

				selectedCategories.push({
					categoryId: resCategory.data.payload.categoryId,
					categoryName: resCategory.data.payload.categoryName,
					isPublished: resCategory.data.payload.isPublished,
				});
			}
		}

		setMedicine((oldVal) => {
			return {
				...oldVal,
				categories: selectedCategories,
			};
		});
	};

	const handleDoseChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		index: number,
	) => {
		const { value } = e.target;
		if (value.length > 20) {
			formRef.current?.setFields([
				{
					name: ['doses', index],
					errors: ['Dose should not exceed 20 characters!'],
				},
			]);
			return;
		} else {
			formRef.current?.setFields([
				{
					name: ['doses', index],
					errors: [],
				},
			]);
		}

		const newDoses = [...doses];
		newDoses[index] = e.target.value;
		setDoses(newDoses);
		newDoses.some((value) => !value.trim())
			? formRef.current?.setFieldsValue({ doses: [] })
			: formRef.current?.setFieldsValue({ doses: [initialMedicineDoses] });
	};

	const handleAddDose = () => {
		setDoses([...doses, '']);
		formRef.current?.setFieldsValue({
			doses: [],
		});
	};

	const handleDoseDelete = (index: number) => {
		if (doses.length === 1) {
			return;
		}
		const newDoses = [...doses];
		newDoses.splice(index, 1);
		setDoses(newDoses);
		newDoses.some((value) => !value.trim())
			? formRef.current?.setFieldsValue({ doses: [] })
			: formRef.current?.setFieldsValue({ doses: [initialMedicineDoses] });
	};

	React.useEffect(() => {
		if (drugMedicineId) {
			const fetchMedicineDetail = async () => {
				try {
					setIsLoading(true);
					let newDoses: string[] = [];

					const res = await httpRequest.get<ResponseProps>(
						'/drug-medicines/' + drugMedicineId,
					);

					const resPartnerStatus = await httpRequest.get<any>(
						'/partners/' + res.data.payload.partnerId,
					);
					let medicineTmp = res.data.payload;
					medicineTmp.partner = resPartnerStatus.data.payload;
					if (
						medicineTmp.partner &&
						medicineTmp.partner.isPublished === false
					) {
						medicineTmp.isPublished = false;
					}

					if (!medicineTmp.partnerId && genericPoint !== -1) {
						medicineTmp.points = genericPoint;
					}
					setMedicine(medicineTmp);

					res.data.payload.doses?.map((item) => {
						newDoses.push(item.dose);
					});
					newDoses && setDoses(newDoses);

					const bcDetails = [
						{
							field: 'drugMedicineId',
							value: drugMedicineId,
							label: res.data.payload.drugMedicineName
								? res.data.payload.drugMedicineName
								: drugMedicineId,
						},
					];
					setBreadcrumbDetails(bcDetails);

					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};

			fetchMedicineDetail();
		} else {
			setDoses(['']);
		}

		const fetchPharmacies = async () => {
			try {
				setIsLoading(true);
				const pharmaciesRes = await httpRequest.get<FetchAllPartnerResponse>(
					'partners?isPublished=1&includePartnerTypes=PHARMACY',
				);
				console.log('pharmaciesRes', pharmaciesRes);
				setPharmacyList(pharmaciesRes.data.payload.results);
			} catch (e) {
				console.log('ERROR::: fetchPharmacies', e);
			} finally {
				setIsLoading(false);
			}
		};

		const getMedicineCategoriesData = async () => {
			const medicineCategories =
				await httpRequest.get<FetchAllCategoryResponse>(
					`/medicine-categories${generateQueryString({
						isPublished: 'active',
					})}`,
				);
			setMedicineCategories(medicineCategories.data.payload.results);
		};

		fetchPharmacies();
		getMedicineCategoriesData();
	}, [drugMedicineId, location, genericPoint]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(drugMedicineId ? 'Edit' : 'Add') + ' Medicine'}
				subtitle="Manage your medicine data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="medicineForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={medicine}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Medicine Data"
						helpers={[
							{
								title: 'Information',
								content: 'You can add multiple tags',
							},
						]}
					>
						<Form.Item
							label="Name"
							name="drugMedicineName"
							rules={generateFormRules('Medicine Name', ['required'])}
						>
							<Input
								value={medicine.drugMedicineName}
								onChange={(e) =>
									setMedicine({
										...medicine,
										drugMedicineName: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Form.Item
							label="Dosage"
							name="doses"
							rules={generateFormRules('Dosage', ['required'])}
						>
							{doses &&
								doses.map((item, currentIndex) => {
									return (
										<Row style={{ marginBottom: '1rem' }}>
											<Col span={23}>
												<Input
													value={item}
													onChange={(e) => handleDoseChange(e, currentIndex)}
												/>
											</Col>
											<Col span={1}>
												<Button
													icon={
														<DeleteOutlined
															style={{
																color: '#D81F64',
															}}
														/>
													}
													disabled={doses.length === 1}
													style={{
														marginLeft: '5px',
													}}
													onClick={() => handleDoseDelete(currentIndex)}
												/>
											</Col>
										</Row>
									);
								})}

							<Row>
								<Col span={24}>
									<Button
										type="primary"
										block
										danger
										style={{
											margin: '1rem 0 20px 0',
											borderColor: '#D81F64',
											backgroundColor: 'white',
											color: '#D81F64',
										}}
										onClick={handleAddDose}
									>
										Add Dosage
									</Button>
								</Col>
							</Row>
						</Form.Item>

						<Form.Item label="Medicine Tag" style={{ marginTop: '10px' }}>
							<Select
								mode="tags"
								allowClear
								style={{
									width: '100%',
								}}
								placeholder="Please select"
								onChange={handleChangeCategories}
								key={'medicineCategorySelect'}
								value={
									medicine.categories
										? medicine.categories.map((item) => item.categoryId || '')
										: []
								}
							>
								{medicineCategories.map((category, index) => {
									return (
										<Option
											key={`category${index}`}
											value={String(category.categoryId || '')}
										>
											{category.categoryName}
										</Option>
									);
								})}
							</Select>
						</Form.Item>

						<Form.Item label="Pharmacy" style={{ marginTop: '10px' }}>
							<Select
								// mode="tags"
								showSearch
								filterOption={(input, option) =>
									(option!.children as unknown as string)
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								allowClear
								style={{
									width: '100%',
								}}
								placeholder="Please select"
								onChange={(value) => {
									setMedicine({
										...medicine,
										partnerId: value,
									});
								}}
								key={'pharmacySelect'}
								value={medicine.partnerId}
								//disabled = {!medicine.partnerId || !medicine.partner?.isPublished}
							>
								{pharmacyList.map((item) => {
									return (
										<Option key={item.partnerId} value={item.partnerId}>
											{item.partnerName}
										</Option>
									);
								})}
							</Select>
						</Form.Item>

						<Form.Item
							label="Points"
							name="points"
							rules={generateFormRules('Points', ['required'])}
						>
							<InputNumber
								value={medicine.points}
								onChange={(e) =>
									setMedicine({
										...medicine,
										points: Number(e),
									})
								}
								min={0}
								disabled={!medicine.partnerId || !medicine.partner?.isPublished}
							/>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Status"
							name="isPublished"
							rules={generateFormRules('Status', ['required'])}
						>
							<Radio.Group
								value={medicine.isPublished}
								disabled={medicine.partner && !medicine.partner.isPublished}
							>
								<Tooltip
									title={
										medicine.partner && !medicine.partner?.isPublished
											? 'Pharmacy status is unpublished'
											: ''
									}
								>
									<CustomRadio
										value={true}
										disabled={medicine.partner && !medicine.partner.isPublished}
									>
										Published
									</CustomRadio>
								</Tooltip>
								<Tooltip
									title={
										medicine.partner && !medicine.partner?.isPublished
											? 'Pharmacy status is unpublished'
											: ''
									}
								>
									<CustomRadio
										value={false}
										disabled={medicine.partner && !medicine.partner.isPublished}
									>
										Unpublished
									</CustomRadio>
								</Tooltip>
							</Radio.Group>
						</Form.Item>

						<Form.Item label="Formulary" name="compositions">
							<TextArea
								rows={4}
								value={medicine.compositions}
								onChange={(e) =>
									setMedicine({
										...medicine,
										compositions: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Form.Item label="Indications" name="indications">
							<TextArea
								rows={4}
								value={medicine.indications}
								onChange={(e) =>
									setMedicine({
										...medicine,
										indications: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Form.Item label="Contraindications" name="contraIndications">
							<TextArea
								rows={4}
								value={medicine.contraIndications}
								onChange={(e) =>
									setMedicine({
										...medicine,
										contraIndications: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Form.Item label="Dose" name="dose">
							<TextArea
								rows={4}
								value={medicine.doseNote}
								onChange={(e) =>
									setMedicine({
										...medicine,
										dose: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Form.Item label="Dose Adjustment" name="doseNote">
							<TextArea
								rows={4}
								value={medicine.doseNote}
								onChange={(e) =>
									setMedicine({
										...medicine,
										doseNote: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Form.Item
							label="Adverse Drug Reactions"
							name="adverseDrugReactions"
						>
							<TextArea
								rows={4}
								value={medicine.adverseDrugReactions}
								onChange={(e) =>
									setMedicine({
										...medicine,
										adverseDrugReactions: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Form.Item label="Administration" name="administration">
							<TextArea
								rows={4}
								value={medicine.administration}
								onChange={(e) =>
									setMedicine({
										...medicine,
										administration: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Form.Item label="Pregnancy Category" name="pregnancyCategory">
							<TextArea
								rows={4}
								style={{
									resize: 'none',
								}}
								onChange={(e) =>
									setMedicine({
										...medicine,
										pregnancyCategory: e.target.value,
									})
								}
							></TextArea>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default MedicineEdit;

const CustomCheckboxGroup = styled(Checkbox.Group)`
	.ant-checkbox-checked .ant-checkbox-inner {
		border-color: red;
		background-color: red;
	}

	.ant-checkbox-group-item {
		margin-right: 5.6rem;
	}
`;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: red;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: red;
	}
`;
