import {
	Form,
	FormInstance,
	Input,
	message,
	DatePicker,
	Radio,
	Select,
	Space,
	Row,
	Col,
	Upload,
	Steps,
	Typography,
	Divider,
	Button,
	Dropdown,
	Menu,
	Checkbox,
	Alert,
} from 'antd';
import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { generateFormRules } from '../../helpers/formRules';
import { generateQueryString } from '../../helpers/generateQueryString';
import { UploadFile } from 'antd/lib/upload/interface';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import {
	CalculatorType,
	EbookProps,
	initialEbooks,
} from '../../types/ebook.type';
import useFetchList from '../../hooks/useFetchList';
import FormUploadImage from '../../components/FormUpload/FormUploadImage';
import styled from 'styled-components';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { InboxOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { GenreProps, FetchAllGenreResponse } from '../../types/genre.type';
import moment from 'moment';
import { Document, Page, pdfjs, Outline } from 'react-pdf';
import {
	PartnerProps,
	FetchAllPartnerResponse,
} from '../../types/partner.type';
import {
	replaceUnderscoreWithSpace,
	UploadInput,
} from '@qlibs/react-components';
import { useAutoAnimate } from '@formkit/auto-animate/react';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`;

const { Step } = Steps;
const { Option } = Select;
const { Title, Text } = Typography;

interface IParams {
	ebookId: string;
	partnerId: string;
}

interface ILocation {
	ebookId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<EbookProps, 'createdAt' | 'updatedAt'>;
}

const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'link'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['clean'],
	],
};

const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'link',
	'list',
	'bullet',
];
interface EbookAccessResponse extends BaseResponseProps {
	payload: boolean;
}
const READERS_OPTION = ['DOCTOR', 'PATIENT'];

const EbookEdit: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const [form] = Form.useForm();
	const location = useLocation<ILocation>();
	const { ebookId, partnerId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<EbookProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);
	const [selectedImage, setSelectedImage] = React.useState<UploadFile<any>>({
		url: '',
		uid: '',
		name: '',
	});
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [ebook, setEbook] = React.useState<EbookProps>(initialEbooks);
	const [genres, setGenre] = React.useState<Array<GenreProps>>([]);
	const [ebookAccess, setEbookAccess] = React.useState(false);
	const [publisherId, setPublisherId] = React.useState('');
	const [outline, setOutline] = React.useState<any[]>([]);
	const [images, setImages] = React.useState<
		UploadChangeParam<UploadFile<any>>['fileList']
	>([]);
	const [image, setImage] = React.useState<File | any | undefined>();
	const [imageUrl, setImageUrl] = React.useState<string | undefined>();
	const [file, setFile] = React.useState<File | undefined>();
	const [fileUrl, setFileUrl] = React.useState<string | undefined>();
	const [checkedList, setCheckedList] = React.useState<CheckboxValueType[]>([]);
	const [currentStep, setCurrentStep] = React.useState<number>(0);
	const [originalPrice, setOriginalPrice] = React.useState<number>(0);
	const [warningMessage, setWarningMessage] = React.useState<string | null>(
		null,
	);
	const [metaEbook, setMetaEbook] = React.useState({});
	const [finalPrice, setFinalPrice] = React.useState<number>(0);
	const [ebookDate, setEbookDate] = React.useState<moment.Moment>(moment());
	const [uploadedFile, setUploadedFile] = React.useState<File>();
	const [showTitleWarning, setShowTitleWarning] = React.useState(false);
	const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
	const [tableOfContent, setTableOfContent] = React.useState<
		{ title: string; pageNumber: number; level: number }[]
	>([{ title: '', pageNumber: 0, level: 0 }]);
	const [videoAttachments, setVideoAttachments] = React.useState([
		{ videoUrl: '', pageNumber: 0 },
	]);
	const [medicalCalculator, setMedicalCalculator] = React.useState(
		ebook.calculator || [],
	);
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [numPages, setNumPages] = React.useState(1);
	const [pageNumber, setPageNumber] = React.useState(1);
	const [errorMessage, setErrorMessage] = React.useState('');
	const { data: publishers } = useFetchList<PartnerProps>({
		endpoint: 'partners',
		initialQuery: {
			includePartnerTypes: 'PUBLISHER',
			isPublished: 'true',
		},
	});
	const [parent] = useAutoAnimate<HTMLDivElement>();

	const createEbook = async (
		props: Omit<
			EbookProps,
			'createdAt' | 'updatedAt' | 'ebookId' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);

			const customOutline = tableOfContent
				.map((content) => ({
					title: content.title,
					pageNumber: content.pageNumber,
					level: content.level,
				}))
				.sort((a, b) => a.pageNumber - b.pageNumber);

			const ebookVideos =
				videoAttachments
					.filter((attachment) => attachment.videoUrl.trim() !== '')
					.map((attachment) => ({
						videoUrl: attachment.videoUrl,
						pageNumber: attachment.pageNumber,
					}))
					.sort((a, b) => a.pageNumber - b.pageNumber) || null;

			const metaEbook = {
				fileSize: file ? file.size / 1024 : undefined,
				totalPages: numPages,
				outline: outline || [],
			};

			const calculator = medicalCalculator.map((content) => ({
				type: content.type,
				page: content.page,
			}));

			const finalPartnerId = partnerId ? partnerId : publisherId;

			const dataToBeSent = {
				...props,
				title: ebook.title,
				description: ebook.description,
				ebookVideos,
				partnerId: finalPartnerId,
				genres: ebook.genres,
				author: ebook.author,
				createdByUserId: ebook.createdByUserId,
				metaCreatedByUser: ebook.metaCreatedByUser,
				publicationDate: ebook.publicationDate,
				bookNumber: ebook.bookNumber,
				originalPrice: ebook.originalPrice,
				finalPrice: ebook.finalPrice,
				metaEbook: JSON.stringify(metaEbook),
				customOutline: JSON.stringify(customOutline),
				targetReaders: ebook.targetReaders.toString(),
				calculator,
			};

			const res: any = await httpRequest.post('/ebooks', dataToBeSent);

			if (res && res.data && res.data.payload && res.data.payload.ebookId) {
				const { ebookId } = res.data.payload;

				console.info('res.data', res.data);
				await uploadNewImage(ebookId);
				await uploadNewFile(ebookId);

				message.success('Success create ' + ebook.title);
				if (partnerId) {
					history.push(`/publisher/${partnerId}/detail`);
				} else {
					history.push('/ebooks');
				}
			} else {
				throw new Error('ebookId not found in response');
			}

			setIsLoadingAction(false);
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateEbook = async (
		props: Omit<EbookProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);

			const customOutline = tableOfContent
				.map((content) => ({
					title: content.title,
					pageNumber: content.pageNumber,
					level: content.level,
				}))
				.sort((a, b) => a.pageNumber - b.pageNumber);

			const ebookVideos =
				videoAttachments
					.filter((attachment) => attachment.videoUrl.trim() !== '')
					.map((attachment) => ({
						videoUrl: attachment.videoUrl,
						pageNumber: attachment.pageNumber,
						ebookId,
					}))
					.sort((a, b) => a.pageNumber - b.pageNumber) || null;

			const metaEbook = {
				fileSize: file ? file.size / 1024 : undefined,
				totalPages: numPages,
				outline: outline || [],
			};

			const calculator = medicalCalculator.map((content) => ({
				type: content.type,
				page: content.page,
			}));

			const dataToBeSent = {
				...props,
				title: ebook.title,
				description: ebook.description,
				// isPublished: ebook.isPublished,
				ebookVideos,
				genres: ebook.genres,
				partners: ebook.partners,
				author: ebook.author,
				publicationDate: ebook.publicationDate,
				bookNumber: ebook.bookNumber,
				originalPrice: ebook.originalPrice,
				finalPrice: ebook.finalPrice,
				metaEbook: JSON.stringify(metaEbook),
				customOutline: JSON.stringify(customOutline),
				targetReaders: ebook.targetReaders.toString(),
				calculator,
			};
			if (ebookId) {
				await Promise.all([
					httpRequest.patch('/ebooks/' + ebookId, dataToBeSent),
					uploadNewImage(ebookId),
					uploadNewFile(ebookId),
				]);
			}

			message.success('Success update ' + ebook.title + ' data');
			if (partnerId) {
				history.push(`/publisher/${partnerId}/detail`);
			} else {
				history.push('/ebooks');
			}
			setIsLoadingAction(false);
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const uploadNewImage = async (ebookId: string) => {
		try {
			if (image) {
				setIsLoadingAction(true);

				let formData = new FormData();
				formData.append('image', image);

				await httpRequest.put(
					'/ebooks/' + ebookId + '/upload-image',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					},
				);
				setIsLoadingAction(false);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleFileUpload = async (ebookId: string, file: File) => {
		try {
			const formData = new FormData();
			formData.append('file', file);

			const res = await httpRequest.put(
				`/ebooks/${ebookId}/upload-file`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			message.success('File uploaded successfully');

			const pdf = await pdfjs.getDocument(URL.createObjectURL(file)).promise;
			const numPages = pdf.numPages;
			let outline = await pdf.getOutline();
			if (!outline) {
				outline = [];
			}
			const updatedOutline = await Promise.all(
				outline.map(async (item) => {
					const pageNumber =
						item.dest && item.dest[0]
							? (await pdf.getPageIndex(item.dest[0])) + 1
							: 0;
					return {
						title: item.title || '',
						pageNumber,
					};
				}),
			);
			console.log('Updated Outline:', updatedOutline);

			setMetaEbook({
				fileSize: file.size / 1024,
				totalPages: numPages,
				outline: updatedOutline,
			});
			setOutline(updatedOutline);
		} catch (err) {
			console.log(err);
		}
	};

	const uploadNewFile = async (ebookId: string) => {
		try {
			if (file) {
				setIsLoadingAction(true);

				let formData = new FormData();
				formData.append('file', file);

				const response = await httpRequest.put(
					'/ebooks/' + ebookId + '/upload-file',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					},
				);
				console.log('Upload file response:', response);

				setIsLoadingAction(false);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleSubmit = async (
		values: Omit<EbookProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		if (ebookId) {
			if (values.originalPrice < 0) {
				message.error('Price cannot be negative.');
				return;
			}

			updateEbook(values);
		} else {
			if (values.originalPrice < 0) {
				message.error('Price cannot be negative.');
				setShowTitleWarning(true);
				return;
			}
			createEbook(values);
		}
	};

	const isCustomOutlineEmpty = (
		tableOfContent: { title: string; pageNumber: number; level: number }[],
	) => {
		return (
			tableOfContent.length === 1 &&
			tableOfContent[0].title === '' &&
			tableOfContent[0].pageNumber === 0 &&
			tableOfContent[0].level === 0
		);
	};

	const isOutlineEmpty = (outline: any[]) => {
		return outline.length === 0;
	};

	const handleChangeEbookDate = (val: moment.Moment) => {
		if (val) {
			const day = val.get('date');
			const month = val.get('month');
			const year = val.get('year');

			setEbookDate(val);

			const startAt = moment().year(year).month(month).date(day);

			const newEbook = {
				...ebook,
				publicationDate: startAt.toDate(),
			};

			setEbook(newEbook);
		}
	};
	const toPascalCase = (str: string) => {
		return str
			.replace(/(?:^\w|[A-Z]|\b\w|\s+|\-|\_)/g, (match, index) =>
				match.toUpperCase(),
			)
			.replace(/\s+|\-|\_/g, '');
	};

	const handleChangeGenre = (value: any) => {
		const selectedGenres = value.map((selectedGenreName: string) => {
			const pascalCaseGenreName = toPascalCase(selectedGenreName);
			const foundGenre = genres.find(
				(genre) =>
					genre.genreName.toLowerCase() === pascalCaseGenreName.toLowerCase(),
			);
			return foundGenre || { genreName: pascalCaseGenreName };
		});

		const genreNames = selectedGenres.map((genre: any) =>
			genre.genreName.toLowerCase(),
		);
		const uniqueGenreNames = new Set(genreNames);
		setIsDuplicate(uniqueGenreNames.size < genreNames.length);

		setEbook((oldVal) => ({
			...oldVal,
			genres: selectedGenres,
		}));
	};

	const handleChangeReaders = (list: CheckboxValueType[]) => {
		setCheckedList(list);
		const selectedReaders = list.map((readers: any) => {
			return readers;
		});

		setEbook((oldVal) => {
			return {
				...oldVal,
				targetReaders: selectedReaders,
			};
		});
	};

	const readFile = (file: UploadFile<any>): Promise<string | undefined> => {
		return new Promise((resolve, reject) => {
			if (file.originFileObj) {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onloadend = function (event) {
					if (event.target && event.target.result) {
						const url =
							event && event.target && event.target.result
								? (event.target.result as any)
								: undefined;
						resolve(url);
					} else {
						resolve(undefined);
					}
				};
			} else {
				resolve(undefined);
			}
		});
	};

	const handleChangeImages = async (e: UploadChangeParam<UploadFile<any>>) => {
		e.fileList.forEach((file) => {
			if (!file || (file && !file.size)) return;
			const isLtMaxSize = (file?.size || 0) / 1024 / 1024 < 1;
			if (!isLtMaxSize) {
				message.error(`Image must smaller than ${1}MB!`);
			}
		});

		setImages(e.fileList);

		const tmpListImage: Array<UploadFile<any> & { url?: string }> = [];
		for (const file of e.fileList) {
			if (file.originFileObj) {
				const url = await readFile(file);
				console.info('url', url);
				tmpListImage.push({
					...file,
					url: url || '',
				});
			}
		}

		setImages(tmpListImage);
		if (!selectedImage || (selectedImage && !selectedImage.uid)) {
			setSelectedImage(tmpListImage[0]);
		}
	};
	const handleAddTableContent = (index: number, level = 0) => {
		const updatedTableOfContent = [...tableOfContent];
		updatedTableOfContent.splice(index + 1, 0, {
			title: '',
			pageNumber: 0,
			level,
		});
		setTableOfContent(updatedTableOfContent);
	};

	const handleAddUnderTableContent = (index: number) => {
		const updatedTableOfContent = [...tableOfContent];
		updatedTableOfContent.splice(index + 1, 0, {
			title: '',
			pageNumber: 0,
			level: tableOfContent[index].level + 1,
		});
		setTableOfContent(updatedTableOfContent);
	};

	const handleRemoveTableContent = (index: number) => {
		if (index === 0) {
			return;
		}

		const updatedTableOfContent = [...tableOfContent];
		updatedTableOfContent.splice(index, 1);
		setTableOfContent(updatedTableOfContent);
	};

	const menu = (index: number) => (
		<Menu>
			<Menu.Item key="0" onClick={() => handleAddTableContent(index, 0)}>
				Add New Title
			</Menu.Item>
			<Menu.Item key="1" onClick={() => handleAddUnderTableContent(index)}>
				Add Under New Title
			</Menu.Item>
		</Menu>
	);

	const sortVideoAttachments = (
		attachments: { videoUrl: string; pageNumber: number }[],
	) => {
		return [...attachments].sort((a, b) => a.pageNumber - b.pageNumber);
	};

	const handleAddVideoAttachment = () => {
		setVideoAttachments([...videoAttachments, { videoUrl: '', pageNumber: 0 }]);
	};

	const handleRemoveVideoAttachment = (index: number) => {
		const updatedVideoAttachments = [...videoAttachments];
		updatedVideoAttachments.splice(index, 1);
		setVideoAttachments(updatedVideoAttachments);
	};

	const handleVideoUrlChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		index: number,
	) => {
		const newVideoAttachments = [...videoAttachments];
		newVideoAttachments[index].videoUrl = e.target.value;
		setVideoAttachments(newVideoAttachments);
	};

	const handlePageNumberChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		index: number,
	) => {
		const newPageNumber = parseInt(e.target.value, 10);
		const newVideoAttachments = [...videoAttachments];
		newVideoAttachments[index].pageNumber = newPageNumber;
		setVideoAttachments(newVideoAttachments);
	};

	const handleSortAttachments = () => {
		const sortedAttachments = sortVideoAttachments(videoAttachments);
		setVideoAttachments(sortedAttachments);
		form.setFieldsValue({
			videoAttachments: sortedAttachments,
		});

		setForceUpdate((prev) => prev + 1);
	};

	const onDocumentLoadSuccess = async ({ numPages }: { numPages: number }) => {
		setNumPages(numPages);
		setPageNumber(1);

		if (file) {
			const pdfUrl = URL.createObjectURL(file);
			const pdf = await pdfjs.getDocument(pdfUrl).promise;
			let outline = await pdf.getOutline();

			if (!outline) {
				outline = [];
			}

			const updatedOutline = await Promise.all(
				outline.map(async (item) => {
					const pageNumber =
						item.dest && item.dest[0]
							? (await pdf.getPageIndex(item.dest[0])) + 1
							: 0;
					return {
						title: item.title || '',
						pageNumber,
						level: item.items ? item.items.length : 0,
					};
				}),
			);

			setOutline(updatedOutline);
			setTableOfContent(
				updatedOutline.length > 0
					? convertOutlineToTableOfContent(updatedOutline)
					: [{ title: '', pageNumber: 0, level: 0 }],
			);
			setMetaEbook((prevMetaEbook) => ({
				...prevMetaEbook,
				totalPages: numPages,
				outline: updatedOutline,
			}));
			console.log('Current outline:', outline);

			URL.revokeObjectURL(pdfUrl);
		} else {
			console.error('File is undefined');
		}
	};

	const handleTitleChange = (
		index: number,
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const newTableOfContent = [...tableOfContent];
		newTableOfContent[index].title = event.target.value;
		setTableOfContent(newTableOfContent);
	};

	const handlePageChange = (
		index: number,
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const newPageNumber = Number(event.target.value);

		if (newPageNumber > numPages) {
			message.error('Page number cannot exceed the total number of pages.');
			return;
		}

		const newTableOfContent = [...tableOfContent];
		newTableOfContent[index].pageNumber = newPageNumber;
		setTableOfContent(newTableOfContent);
	};

	const convertOutlineToTableOfContent = (outline: any[]): any[] => {
		return outline.map((item) => ({
			title: item.title || '',
			pageNumber: item.pageNumber ? item.pageNumber.toString() : '',
			level: item.level || 0,
		}));
	};

	const handleTypeChange = (value: any, index: number) => {
		const updatedCalculators = [...medicalCalculator];
		updatedCalculators[index].type = value;
		setMedicalCalculator(updatedCalculators);
	};

	const handlePageNumberChangeCalculator = (
		e: React.ChangeEvent<HTMLInputElement>,
		index: number,
	) => {
		const newPageNumber = Number(e.target.value);

		if (newPageNumber > numPages) {
			message.error('Page number cannot exceed the total number of pages.');
			return;
		}

		const isDuplicate = medicalCalculator.some(
			(calculator, i) => i !== index && calculator.page === newPageNumber,
		);
		if (isDuplicate) {
			message.error('Page number must be unique.');
			return;
		}
		const updatedCalculators = [...medicalCalculator];
		updatedCalculators[index].page = newPageNumber;
		setMedicalCalculator(updatedCalculators);
	};

	const handleAddMedicalCalculator = () => {
		setMedicalCalculator([...medicalCalculator, { type: '', page: 0 }]);
	};

	const handleRemoveMedicalCalculator = (index: number) => {
		const updatedCalculators = medicalCalculator.filter((_, i) => i !== index);
		setMedicalCalculator(updatedCalculators);
	};

	const handlePriceChange = (e: any) => {
		const value = parseInt(e.target.value);

		if (!isNaN(value) && value >= 0) {
			setOriginalPrice(value);
			setEbook((prevEbook) => ({
				...prevEbook,
				originalPrice: value,
			}));
			setErrorMessage('');
		} else {
			setOriginalPrice(0);
			setEbook((prevEbook) => ({
				...prevEbook,
				originalPrice: 0,
			}));
			setErrorMessage('Price cannot be negative');
		}
	};

	React.useEffect(() => {
		let timeout: NodeJS.Timeout;
		if (finalPrice > originalPrice) {
			setWarningMessage(
				'Final Price cannot be greater than Original Price. It will be adjusted to match the Original Price.',
			);
			timeout = setTimeout(() => {
				setFinalPrice(originalPrice);
				setEbook((prevEbook) => ({
					...prevEbook,
					finalPrice: originalPrice,
				}));
				setWarningMessage('');
			}, 2000);
		}
		return () => clearTimeout(timeout);
	}, [finalPrice, originalPrice]);

	React.useEffect(() => {
		if (ebook.finalPrice) {
			setFinalPrice(ebook.finalPrice);
		}
		if (ebook.originalPrice) {
			setOriginalPrice(ebook.originalPrice);
		}
	}, [ebook]);

	React.useEffect(() => {
		if (ebookId) {
			const fetchEbookDetail = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/ebooks/' + ebookId,
					);
					const dataImages = res.data.payload;

					setImageUrl(dataImages.imageUrl);
					setFileUrl(dataImages.fileUrl);

					if (dataImages.ebookVideos) {
						const fetchedVideoAttachments = dataImages.ebookVideos.map(
							(video: any) => ({
								videoUrl: video.videoUrl || '',
								pageNumber: video.pageNumber || 0,
							}),
						);

						setVideoAttachments(fetchedVideoAttachments);
						form.setFieldsValue({
							videoAttachments: fetchedVideoAttachments,
						});
					}

					if (dataImages.fileUrl) {
						const response = await fetch(dataImages.fileUrl);
						const blob = await response.blob();
						const file = new File([blob], 'filename.pdf', { type: blob.type });
						setUploadedFile(file);
					}

					const fetchedEbook: EbookProps = {
						...res.data.payload,
						createdAt: new Date(),
						updatedAt: new Date(),
					};

					setEbook(fetchedEbook);

					setEbookDate(moment(res.data.payload.publicationDate));

					const bcDetails = [
						{
							field: 'ebookId',
							value: ebookId,
							label: res.data.payload.title,
						},
					];
					setBreadcrumbDetails(bcDetails);
					if (res.data.payload.customOutline) {
						setTableOfContent(JSON.parse(res.data.payload.customOutline));
					}

					if (res.data.payload.calculator) {
						setMedicalCalculator(res.data.payload.calculator);
					}

					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};

			fetchEbookDetail();
		}

		const getGenreData = async () => {
			const genreRes = await httpRequest.get<FetchAllGenreResponse>(
				`/genre${generateQueryString({ isPublished: true })}`,
			);

			setGenre(genreRes.data.payload.results);
		};

		getGenreData();
	}, [ebookId, location]);

	React.useEffect(() => {
		if (finalPrice > originalPrice) {
			setWarningMessage('Final Price cannot be greater than Original Price.');
			setFinalPrice(originalPrice);
			setEbook((prevEbook) => ({
				...prevEbook,
				finalPrice: originalPrice,
			}));
			setTimeout(() => {
				setWarningMessage('');
			}, 3000);
		}
	}, [originalPrice, finalPrice]);

	React.useEffect(() => {
		const fetchEbookAccess = async () => {
			try {
				const response = await httpRequest.get<EbookAccessResponse>(
					`/ebooks/${ebookId}/find-by-transaction`,
				);
				if (response.data?.payload === true) {
					setEbookAccess(true);
				} else {
					setEbookAccess(false);
				}
			} catch (error) {
				console.error('Failed to fetch ebook access:', error);
				setEbookAccess(false);
			}
		};

		fetchEbookAccess();
	}, [ebookId]);

	React.useEffect(() => {
		setMedicalCalculator(ebook.calculator || []);
	}, [ebookId]);

	const PublisherField = !partnerId;

	const initialFormValues = ebookId
		? {
				...ebook,
				videoAttachments,
				file: imageUrl ? [{ url: imageUrl }] : [],
				publisher: ebook.partnerId,
		  }
		: initialEbooks;

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(ebookId ? 'Edit' : 'Add') + ' Ebook'}
				subtitle="Manage your Ebook data"
				rightAction={
					<Space>
						{currentStep === 0 ? (
							<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						) : (
							<AppButton onClick={() => setCurrentStep(currentStep - 1)}>
								Previous
							</AppButton>
						)}
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => {
								if (currentStep === 2) {
									formRef.current?.submit();
								} else {
									formRef.current
										?.validateFields()
										.then(() => {
											if (
												currentStep === 1 &&
												isCustomOutlineEmpty(tableOfContent) &&
												isOutlineEmpty(outline)
											) {
												message.error(
													'Please provide either table of content or file with table of content.',
												);
												setShowTitleWarning(true);
												return;
											}
											setCurrentStep(currentStep + 1);
										})
										.catch((errorInfo) => {
											console.error('Validation failed:', errorInfo);
										});
								}
							}}
						>
							{currentStep === 2 ? 'Save' : 'Next'}
						</AppButton>
					</Space>
				}
			/>
			<CustomSteps current={currentStep} direction="horizontal">
				<Step title="Details" description="Add Information" />
				<Step title="Upload File" description="Manage Your Ebook" />
				<Step title="Pricing & Visibility" description="Set Pricing & Status" />
			</CustomSteps>
			<br></br>

			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="ebookForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={initialFormValues}
					autoComplete="off"
					key={forceUpdate}
				>
					{currentStep === 0 && (
						<div>
							<SectionContent
								groupTitle="Ebook Category"
								helpers={[
									{
										title: 'Information',
										content: (
											<div
												style={{
													maxWidth: '80%',
													wordWrap: 'break-word',
													overflow: 'hidden',
													whiteSpace: 'normal',
												}}
											>
												Accepted image type are jpg, jpeg and png. Max file size
												is 1 Mb.
											</div>
										),
									},
								]}
							>
								{/* <h4>Ebook Cover</h4> */}
								{/* <FormUploadImage
									mode={'single-large'}
									maxSizeInMB={5}
									imageUrl={imageUrl || ''}
									onChange={(file) => {
										setImage(file);
									}}
									onPreviewChange={(previewUrl) => setImageUrl(previewUrl)}
								/> */}
								<Form.Item
									name="file"
									label="EBook Cover"
									rules={generateFormRules('Cover', ['required'])}
								>
									{!imageUrl ? (
										<UploadInput
											additionalHint={imageUrl}
											maxFileSize={1}
											onChange={(file) => {
												setImage(file);
											}}
											allowedExtension={['jpg', 'png']}
											enableAutoResizeForImage={true}
											configAutoResizeForImage={{
												maxFileWidth: 350,
											}}
										/>
									) : (
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											<img
												src={imageUrl}
												alt="EBook Cover"
												style={{
													maxWidth: '100%',
													maxHeight: '200px',
													objectFit: 'contain',
												}}
											/>
										</div>
									)}
								</Form.Item>
								<Form.Item
									label="Title"
									name="title"
									rules={generateFormRules('Title', ['required'])}
								>
									<Input
										value={ebook.title}
										onChange={(e) =>
											setEbook({
												...ebook,
												title: e.target.value,
											})
										}
									/>
								</Form.Item>
								<Form.Item
									label="Description"
									name="description"
									rules={generateFormRules('Detail', ['required'])}
								>
									<ReactQuill
										theme="snow"
										value={ebook.description}
										onChange={(val) =>
											setEbook({
												...ebook,
												description: val,
											})
										}
										modules={quillModules}
										formats={quillFormats}
									/>
								</Form.Item>

								<Form.Item
									label="Genre"
									rules={[
										{
											required: true,
											message: 'Please select Genre',
										},
									]}
								>
									<Select
										mode="tags"
										style={{ width: '100%' }}
										placeholder="Input genre"
										onChange={handleChangeGenre}
										key={'genreSelect'}
										value={
											ebook.genres
												? ebook.genres.map((item) => item.genreName || '')
												: []
										}
									>
										{genres.map((genres, index) => {
											return (
												<Option
													key={`genres${index}`}
													value={String(genres.genreName)}
												>
													{genres.genreName}
												</Option>
											);
										})}
									</Select>
									{isDuplicate && (
										<Text style={{ color: 'red', fontSize: 13 }}>
											Category Name Already Exist!
										</Text>
									)}
								</Form.Item>

								{PublisherField && (
									<Form.Item
										label="Publisher"
										name="publisher"
										rules={generateFormRules('Publisher', ['required'])}
									>
										<Select onChange={(value) => setPublisherId(value)}>
											{publishers.map((item, index) => (
												<Option
													key={`publisher${index}`}
													value={String(item.partnerId)}
												>
													{item.partnerName}
												</Option>
											))}
										</Select>
									</Form.Item>
								)}
								<Form.Item
									label="ISBN"
									name="bookNumber"
									rules={generateFormRules('ISBN', ['required'])}
								>
									<Input
										value={ebook.bookNumber}
										onChange={(e) =>
											setEbook({
												...ebook,
												bookNumber: e.target.value,
											})
										}
									/>
								</Form.Item>

								<Form.Item
									label="Author"
									name="author"
									rules={generateFormRules('Author', ['required'])}
								>
									<Input
										value={ebook.author}
										onChange={(e) =>
											setEbook({
												...ebook,
												author: e.target.value,
											})
										}
									/>
								</Form.Item>

								<Form>
									<Form.Item label="Published Date" name="publicationDate">
										<DatePicker
											style={{
												width: '100%',
											}}
											defaultValue={
												ebookId
													? moment(ebook.publicationDate, 'YYYY/MM/DD')
													: moment()
											}
											onChange={(val) =>
												val
													? handleChangeEbookDate(val)
													: setEbookDate(moment())
											}
										/>
									</Form.Item>
								</Form>
							</SectionContent>
						</div>
					)}
					{currentStep === 1 && (
						<div>
							<SectionContent
								groupTitle="File Customize"
								helpers={[
									{
										title: 'Information',
										content: (
											<div
												style={{
													maxWidth: '90%',
													wordWrap: 'break-word',
													overflow: 'hidden',
													whiteSpace: 'normal',
												}}
											>
												{!ebookAccess
													? 'Accepted file type are pdf, with A4 Portrait (8:11) or A5 Portrait (5:8) and Max file size is 10 MB.'
													: 'The E-Book File cannot be modified because it has already been sold.'}
											</div>
										),
									},
								]}
							>
								<Form.Item
									label="E-book File"
									name="fileUrl"
									rules={[
										{
											required: !uploadedFile,
											message: 'File Is Required',
										},
									]}
								>
									{!uploadedFile ? (
										<CustomUpload
											name="files"
											accept=".pdf"
											beforeUpload={(file) => {
												if (file.size > 10 * 1024 * 1024) {
													message.error('File size must be less than 10 MB');
													return false;
												}
												setFile(file);
												setUploadedFile(file);
												handleFileUpload(ebookId, file);
												return false;
											}}
											multiple={false}
										>
											<p className="ant-upload-drag-icon">
												<InboxOutlined />
											</p>
											<p className="ant-upload-text">
												Click or drag file to this area to upload
											</p>
											<p className="ant-upload-hint">
												Support for a single file upload only
											</p>
										</CustomUpload>
									) : (
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
											}}
										>
											<div
												style={{
													backgroundColor: '#f0f0f0',
													padding: 6,
													border: '1px solid #ccc',
													borderRadius: 5,
												}}
											>
												<Document
													file={uploadedFile}
													onLoadSuccess={onDocumentLoadSuccess}
													className="custom-pdf-document"
												>
													<Page pageNumber={pageNumber} />
												</Document>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													gap: 5,
												}}
											>
												<div
													style={{ cursor: 'pointer' }}
													onClick={() =>
														pageNumber - 1 > 0 && setPageNumber(pageNumber - 1)
													}
												>
													{'<<'}
												</div>
												<div>
													Page {pageNumber} of {numPages}
												</div>
												<div
													style={{ cursor: 'pointer' }}
													onClick={() =>
														pageNumber + 1 <= numPages &&
														setPageNumber(pageNumber + 1)
													}
												>
													{'>>'}
												</div>
											</div>
										</div>
									)}
									<br />
									{!ebookAccess && (
										<CustomFileUploadLabel>
											<input
												type="file"
												accept=".pdf"
												onChange={(e) => {
													const file = e.target.files && e.target.files[0];
													if (file) {
														if (file.size > 10 * 1024 * 1024) {
															message.error(
																'File size must be less than 10 MB',
															);
															return;
														}
														setFile(file);
														setUploadedFile(file);
														handleFileUpload(ebookId, file);
													}
												}}
											/>
											Choose File
										</CustomFileUploadLabel>
									)}

									{outline.length > 0 && (
										<div
											style={{
												color: 'orange',
												marginTop: '12px',
												textAlign: 'center',
											}}
										>
											The uploaded PDF contains an table of content.
										</div>
									)}
								</Form.Item>
							</SectionContent>
							<Divider />
							<SectionContent
								groupTitle="Table Of Content"
								helpers={[
									{
										title: 'Information',
										content:
											'Adding a table of contents is optional, but when you add it, it will replace the table of contents of the uploaded file.',
									},
								]}
							>
								{tableOfContent.map((content, index) => (
									<Row
										key={index}
										gutter={16}
										align="middle"
										style={{ marginLeft: `${content.level * 20}px` }}
									>
										<Col span={16}>
											<Form.Item
												label={index === 0 ? 'Title' : ''}
												validateStatus={
													showTitleWarning && content.title === ''
														? 'error'
														: undefined
												}
												help={
													showTitleWarning && content.title === ''
														? 'Please Input the Table of Content'
														: undefined
												}
												rules={[
													{ required: true, message: 'Title is required' },
												]}
											>
												<Input
													placeholder="Input Here"
													value={content.title}
													onChange={(e) => handleTitleChange(index, e)}
												/>
											</Form.Item>
										</Col>
										<Col span={4}>
											<Form.Item
												label={index === 0 ? 'Page' : ''}
												rules={[
													{
														required: true,
														message: 'Page number is required',
													},
													{
														type: 'number',
														min: 0,
														message:
															'Page number must be a non-negative number',
													},
												]}
											>
												<Input
													placeholder="Input Page Number"
													min="0"
													type="number"
													value={content.pageNumber}
													onChange={(e) => handlePageChange(index, e)}
												/>
											</Form.Item>
										</Col>
										<Col
											span={2}
											style={{
												display: 'flex',
												justifyContent: 'center',
												marginTop: index === 0 ? '17px' : '0',
												marginBottom:
													index !== 0 && index === tableOfContent.length - 1
														? '10px'
														: '0',
											}}
										>
											<Button
												type="primary"
												danger
												style={{
													backgroundColor: 'white',
													borderColor: '#ccc8c8',
												}}
												icon={<DeleteOutlined style={{ color: '#d81f64' }} />}
												onClick={() => handleRemoveTableContent(index)}
											></Button>
										</Col>
										<Col
											span={2}
											style={{
												display: 'flex',
												justifyContent: 'center',
												marginTop: index === 0 ? '17px' : '0',
												marginBottom:
													index !== 0 && index === tableOfContent.length - 1
														? '10px'
														: '0',
											}}
										>
											<Dropdown overlay={menu(index)} trigger={['click']}>
												<Button
													type="primary"
													style={{
														backgroundColor: 'white',
														borderColor: '#ccc8c8',
													}}
													icon={<PlusOutlined style={{ color: '#d81f64' }} />}
													onClick={(e) => e.preventDefault()}
												/>
											</Dropdown>
										</Col>
									</Row>
								))}
							</SectionContent>

							<Divider />
							<SectionContent
								groupTitle="Video Attachment"
								helpers={[
									{
										title: 'Information',
										content:
											'Enter the video url and link to the page to play on the related page',
									},
								]}
							>
								<div ref={parent}>
									{videoAttachments.map((attachment, index) => (
										<Row key={index} gutter={16} align="middle">
											<Col span={18}>
												<Form.Item
													label={index === 0 ? 'Video URL' : ''}
													name={['videoAttachments', index, 'videoUrl']}
													rules={[
														{
															pattern:
																/^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/shorts\/)[\w\-]+/,
															message: 'Please input a valid YouTube URL',
														},
													]}
												>
													<Input
														placeholder="Input link here (e.g., https://www.youtube.com/)"
														value={attachment.videoUrl}
														onChange={(e) => handleVideoUrlChange(e, index)}
													/>
												</Form.Item>
											</Col>
											<Col span={4}>
												<Form.Item
													label={index === 0 ? 'Page' : ''}
													name={['videoAttachments', index, 'pageNumber']}
												>
													<Input
														type="number"
														min="0"
														value={attachment.pageNumber}
														onChange={(e) => handlePageNumberChange(e, index)}
													/>
												</Form.Item>
											</Col>
											<Col
												span={2}
												style={{
													display: 'flex',
													justifyContent: 'center',
													marginTop: index === 0 ? '17px' : '0',
													marginBottom:
														index !== 0 && index === videoAttachments.length - 1
															? '10px'
															: '0',
												}}
											>
												<Button
													type="primary"
													danger
													style={{
														backgroundColor: 'white',
														borderColor: '#ccc8c8',
													}}
													icon={<DeleteOutlined style={{ color: '#d81f64' }} />}
													onClick={() => handleRemoveVideoAttachment(index)}
												/>
											</Col>
										</Row>
									))}
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										marginTop: '10px',
									}}
								>
									<Button
										type="primary"
										onClick={handleAddVideoAttachment}
										style={{
											backgroundColor: 'white',
											borderColor: '#d81f64',
											color: '#d81f64',
											width: '75%',
										}}
									>
										Add Video
									</Button>
									<Button
										type="primary"
										onClick={handleSortAttachments}
										style={{
											backgroundColor: 'white',
											borderColor: '#d81f64',
											color: '#d81f64',
											width: '24%',
										}}
									>
										Sort Videos
									</Button>
								</div>
							</SectionContent>

							<Divider />
							<SectionContent
								groupTitle="Medical Calculator"
								helpers={[
									{
										title: 'Information',
										content:
											'Select a calculator and link it to the related page to display.',
									},
								]}
							>
								<div>
									{medicalCalculator.map((calculator, index) => (
										<Row key={index} gutter={16} align="middle">
											<Col span={18}>
												<Form.Item label={index === 0 ? 'Calculator Type' : ''}>
													<Select
														placeholder="Select a calculator type"
														value={calculator.type}
														onChange={(value) => handleTypeChange(value, index)}
													>
														{Object.entries(CalculatorType).map(
															([key, value]) => (
																<Select.Option key={key} value={key}>
																	{replaceUnderscoreWithSpace(value)}
																</Select.Option>
															),
														)}
													</Select>
												</Form.Item>
											</Col>
											<Col span={4}>
												<Form.Item label={index === 0 ? 'Page' : ''}>
													<Input
														type="number"
														min="0"
														value={calculator.page}
														onChange={(e) =>
															handlePageNumberChangeCalculator(e, index)
														}
													/>
												</Form.Item>
											</Col>
											<Col
												span={2}
												style={{
													display: 'flex',
													justifyContent: 'center',
													marginTop: index === 0 ? '17px' : '0',
													marginBottom:
														index !== 0 &&
														index === medicalCalculator.length - 1
															? '10px'
															: '0',
												}}
											>
												<Button
													type="primary"
													danger
													style={{
														backgroundColor: 'white',
														borderColor: '#ccc8c8',
													}}
													icon={<DeleteOutlined style={{ color: '#d81f64' }} />}
													onClick={() => handleRemoveMedicalCalculator(index)}
												/>
											</Col>
										</Row>
									))}
									<Button
										type="primary"
										onClick={handleAddMedicalCalculator}
										style={{
											backgroundColor: 'white',
											borderColor: '#d81f64',
											color: '#d81f64',
											width: '100%',
										}}
									>
										Add Calculator
									</Button>
								</div>
							</SectionContent>
						</div>
					)}

					{currentStep === 2 && (
						<div>
							<SectionContent
								groupTitle="E-Book Price & Status"
								helpers={[
									{
										title: 'Information',
										content:
											'Input the original price,final price and select the status',
									},
								]}
							>
								<Form.Item
									label="Original Price"
									name="originalPrice"
									rules={[
										{
											required: true,
											message: 'Please input the price!',
										},
										{
											validator: (_, value) =>
												value >= 0
													? Promise.resolve()
													: Promise.reject('Price cannot be negative'),
										},
									]}
								>
									<Input
										type="number"
										min="0"
										onChange={handlePriceChange}
										prefix="₱"
									/>
								</Form.Item>

								<Form.Item
									label="Final Price"
									name="finalPrice"
									rules={generateFormRules('Final Price', ['required'])}
								>
									<Input
										type="number"
										min="0"
										value={finalPrice}
										onChange={(e) => {
											const value = parseInt(e.target.value);
											if (value < 0) {
												setWarningMessage('Price cannot be negative.');
												setFinalPrice(0);
												setEbook((prevEbook) => ({
													...prevEbook,
													finalPrice: 0,
												}));
											} else if (value > originalPrice) {
												setWarningMessage(
													'Final Price cannot be greater than Original Price. It has been adjusted to match the Original Price.',
												);
												setFinalPrice(originalPrice);
												setEbook((prevEbook) => ({
													...prevEbook,
													finalPrice: originalPrice,
												}));
											} else {
												setWarningMessage(null);
												setFinalPrice(value);
												setEbook((prevEbook) => ({
													...prevEbook,
													finalPrice: value,
												}));
											}
										}}
										prefix="₱"
									/>
									{warningMessage && (
										<span style={{ color: 'orange', fontSize: 12 }}>
											{warningMessage}
										</span>
									)}
								</Form.Item>
								<Form.Item
									label="Status"
									name="isPublished"
									rules={[
										{
											required: true,
											message: 'The Status is required.',
										},
									]}
								>
									<Radio.Group value={ebook.isPublished}>
										<Radio value={true}>Published</Radio>
										<Radio value={false}>Unpublished</Radio>
									</Radio.Group>
								</Form.Item>
								<Form.Item
									style={{
										paddingTop: '2%',
									}}
									label="Publish For"
									name="targetReaders"
									initialValue={ebook.targetReaders}
									rules={generateFormRules('Publish For', ['required'])}
								>
									<CustomCheckboxGroup
										options={READERS_OPTION}
										onChange={handleChangeReaders}
										value={checkedList}
									/>
								</Form.Item>
							</SectionContent>
						</div>
					)}
				</Form>
			</AppCard>
		</div>
	);
};

const CustomFileUploadLabel = styled.label`
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
	background-color: #e82069;
	color: white;
	border-radius: 4px;
	text-align: center;
	input[type='file'] {
		display: none;
	}
	width: 100%;
`;

const CustomCheckboxGroup = styled(Checkbox.Group)`
	.ant-checkbox-checked .ant-checkbox-inner {
		border-color: red;
		background-color: red;
	}

	.ant-checkbox-group-item {
		margin-right: 5.6rem;
	}
`;

const CustomSpace = styled(Space)`
	width: 100%;
	.ant-space-item {
		width: 100%;
	}
`;

const CustomUpload = styled(Upload.Dragger)`
	width: 100%;
	.ant-upload {
		width: 100%;
	}
`;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: #d81f64;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: #d81f64;
	}
`;

const CustomSteps = styled(Steps)`
	.ant-steps-item-finish .ant-steps-item-icon {
		background-color: green;
		border-color: green;
	}

	.ant-steps-item-process .ant-steps-item-icon {
		background-color: #d81f64;
		border-color: #d81f64;
	}

	.ant-steps-item-wait .ant-steps-item-icon {
		background-color: white;
		border-color: #ccc8c8;
	}

	.ant-steps-item-title {
		color: #d81f64;
	}

	.ant-steps-item-finish .ant-steps-item-title,
	.ant-steps-item-process .ant-steps-item-title {
		color: #d81f64;
	}

	.ant-steps-item-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 8px;
	}

	.ant-steps-item-finish .ant-steps-icon {
		color: #fff;
	}

	.ant-steps-item-process .ant-steps-icon {
		color: #fff;
	}

	.ant-steps-item-wait .ant-steps-icon {
		color: #0a0a0a;
	}

	.ant-steps-item {
		display: flex;
		align-items: center;
		flex-direction: row;
	}

	.ant-steps-item-content {
		display: flex;
		align-items: flex-start;
		flex-direction: column; /* Tampilkan teks deskripsi di bawah judul */
	}
	.ant-steps-item-description {
		margin-top: -8px;
	}

	.ant-steps-item-container {
		display: flex;
		align-items: center;
	}

	.ant-steps-item-tail {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 50%;
		width: 100%;
		height: 2px;
		background: #d81f64;
	}
`;

export default EbookEdit;
